dmx.Component('video', {

  constructor: function(node, parent) {
    this.inView = this.inView.bind(this);
    this.updateState = dmx.throttle(this.updateState.bind(this));
    dmx.BaseComponent.call(this, node, parent);
  },

  initialData: {
      position: 0,
      duration: 0,
      ended: false,
      muted: false,
      paused: false,
      playbackRate: 1,
      volume: 1,
      inView: false
  },

  tag: 'video',

  attributes: {
    src: {
      type: String,
      default: null
    },

    autopause: {
      type: Boolean,
      default: false
    },

    playinview: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    position: function(pos) {
      this.$node.currentTime = pos;
    },

    playbackRate: function(rate) {
      this.$node.playbackRate = rate;
    },

    volume: function(volume) {
      this.$node.volume = volume;
    },

    load: function(src) {
      this.$node.src = src;
      this.$node.load();
    },

    play: function() {
      this.$node.play();
    },

    pause: function() {
      this.$node.pause();
    }
  },

  render: function(node) {
    dmx.BaseComponent.prototype.render.call(this, node);

    this.$node.ondurationchange = this.updateState;
    this.$node.onended = this.updateState;
    this.$node.onpause = this.updateState;
    this.$node.onplay = this.updateState;
    this.$node.onplaying = this.updateState;
    this.$node.onratechange = this.updateState;
    this.$node.onseeked = this.updateState;
    this.$node.ontimeupdate = this.updateState;
    this.$node.onvolumechange = this.updateState;

    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const inView = entry.isIntersecting;

          if (!inView && this.data.inView && this.props.autopause) {
            this.$node.pause();
          }
      
          if (inView && !this.data.inView && this.props.playinview) {
            this.$node.play();
          }
      
          this.set('inView', inView);
        });
      });
      this.observer.observe(this.$node);
    } else {
      window.addEventListener('scroll', this.inView);
      window.addEventListener('resize', this.inView);
      this.inView();
    }

    this.update({});
    this.updateState();
  },

  inView: function() {
    var rect = this.$node.getBoundingClientRect();
    var inView = (
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth)
    );

    if (!inView && this.data.inView && this.props.autopause) {
      this.$node.pause();
    }

    if (inView && !this.data.inView && this.props.playinview) {
      this.$node.play();
    }

    this.set('inView', inView);
  },

  update: function(props) {
    if (props.src != this.props.src) {
      this.$node.src = this.props.src;
      this.$node.load();
    }
  },

  updateState: function() {
    this.set('position', this.$node.currentTime);
    this.set('duration', this.$node.duration == NaN ? 0 : this.$node.duration);
    this.set('ended', this.$node.ended);
    this.set('muted', this.$node.muted);
    this.set('paused', this.$node.paused);
    this.set('playbackRate', this.$node.playbackRate);
    this.set('volume', this.$node.volume);
  },

  destroy: function() {
    if (window.IntersectionObserver) {
      this.observer.disconnect();
      this.observer = null;
    } else {
      window.removeEventListener('scroll', this.inView);
      window.removeEventListener('resize', this.inView);
    }
  }

});
